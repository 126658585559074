.yss-virtual-try-btn {
  text-decoration: none;
  color: black;
  background-color: #e8c8b3;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 16px;
}

.yss-widget--container {
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #bbc2b8;
  font-family: Euclid Regular;
}

.yss-widget--logo-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.yss-widget--logo-icon path {
  stroke: white;
}

.yss-widget--logo-text {
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.yss-widget--title {
  font-size: 48px;
  font-weight: regular;
  max-width: 400px;
  color: white;
  margin: 0;
}

.yss-widget--text {
  color: white;
  margin: 0;
  max-width: 500px;
}

.yss-widget--link {
  text-decoration: none;
  color: black;
  background-color: white;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 16px;
  width: fit-content;
}
