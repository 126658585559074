input[type='range'] {
  overflow: hidden;
  -webkit-appearance: none;
  background-color: rgba(240, 237, 238, 0.5);
  height: 100%;
  width: 100%;
  border-radius: 200px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 200px !important;
  background: black;
  box-shadow: -500px 50px 20px 500px white;
}

::-webkit-scrollbar {
  width: 0;
}

.yss-virtual-try-on-btn {
  background-color: #e8c8b3;
  color: black;
  padding: 8px 24px;
  border-radius: 24px;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
}

.yss-bliss-slider::-webkit-slider-thumb {
  box-shadow: -500px 50px 20px 500px var(--theme-ui-colors-blissCyan) !important;
}

.yss-bliss-slider {
  background-color: var(--theme-ui-colors-blissWhite) !important;
}
