@font-face {
  font-family: 'Euclid Regular';
  src: url('EuclidCircularARegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Euclid Semi Bold';
  src: url('EuclidCircularASemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Euclid Bold';
  src: url('EuclidCircularABold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Castledown Discovery Bold';
  src: url('CastledownDiscoveryBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Quicksand Regular';
  src: url('QuicksandRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Quicksand Bold';
  src: url('QuicksandBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'NeueFrutigerWorld';
  src: url('NeueFrutigerWorld-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'NeueFrutigerWorld';
  src: url('NeueFrutigerWorld-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'NeueFrutigerWorld';
  src: url('NeueFrutigerWorld-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
