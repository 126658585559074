.tippy-box {
  background-color: white;
  font-size: inherit !important;
  outline: none;
  box-shadow: 0px 2.18550705909729px 17.48405647277832px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}
.tippy-box-dropdown {
  background-color: transparent !important;
  font-size: inherit !important;
  outline: none;
}

.tippy-box-dropdown .tippy-content {
  padding: 0px !important;
  outline: none !important;
}

.tippy-box-dropdown > .tippy-arrow {
  color: #0fccc3 !important;
}
