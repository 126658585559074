.pagination-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 18px;
}

.pagination-list .page > a {
  margin: 0 8px;
  color: #d5d9dd;
  cursor: pointer;
}

.pagination-list .active > a {
  color: #0fccc3;
  cursor: pointer;
}
