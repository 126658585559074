/* CSS variables. */
:root {
  --PhoneInput-color: #d5d9dd;
}

.PhoneInput {
  padding: 20px;
  width: 100%;
  height: 64px;
  background-color: var(--theme-ui-colors-white);
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  border-radius: 8px;
  outline: none;
}

.PhoneInputInput {
  padding: 20px;
  border: none;
  outline: none;
  font-size: 16px;
  color: #394147 !important;
}

.PhoneInputInput:focus {
  border: none;
  outline: none;
}

.PhoneInputCountryIcon {
  height: 120px;
  width: 50px;
}
