.toast-container {
  background: none !important;
  bottom: 16px;
  padding: 0 !important;
  right: 16px;
  width: auto !important;
}

.toast-message {
  background: none !important;
  border-radius: 4px;
  box-shadow: unset;
  margin: 0;
  margin-top: 16px;
  min-height: auto;
  padding: 0 !important;
  width: auto !important;
}

.Toastify__toast {
  min-height: unset;
}
