.margin-right-8 {
  margin-right: 8px;
}
.margin-right-16 {
  margin-right: 16px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-32 {
  margin-top: 32px;
}

.pointer {
  cursor: pointer;
}
